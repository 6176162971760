@import 'src/styles/vendor/animate.scss';

.albumsListContainer {
  position: fixed;
  z-index: 9;
  height: 100vh;
  $time: 1s;
  -webkit-animation: fadeIn $time;
  -moz-animation: fadeIn $time;
  -ms-animation: fadeIn $time;
  -o-animation: fadeIn $time;
  animation: fadeIn $time;
}

.albumsList {
  user-select: none;
  min-width: 266px;
  position: relative;
  margin: 90px 8px 0 8px;
  z-index: 10;

  $time: 0.2s;
  -webkit-animation: fadeInLeft $time;
  animation: fadeInLeft $time;

  a {
    width: 230px;
    margin-bottom: 2px;
    display: block;
    color: #fff;
    padding: 10px 10px 10px 25px;
    text-decoration: none;
    text-align: left;
    font-size: 11pt;
    text-transform: uppercase;
    border-radius: 4px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .2s all;

    &.active,
    &:hover {
      color: #fff;
      background: rgba(0, 42, 8, 1);
      box-shadow: 1px 0 8px rgb(0 0 0 / 50%) inset;
    }
  }
}

.logo {
  transition: .1s all;
  user-select: none;
  display: block;
  width: 370px;
  height: 136px;
  position: absolute;
  top: 10px;
  left: 48px;
  z-index: 9;

  &:hover {
    filter: opacity(0.95) sepia(20%);
  }

  img {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  }
}
