@import 'src/styles/vendor/animate.scss';

.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 9;

  $time: 1s;
  -webkit-animation: fadeIn $time;
  -moz-animation: fadeIn $time;
  -ms-animation: fadeIn $time;
  -o-animation: fadeIn $time;
  animation: fadeIn $time;

  &Home {
    width: 220px;
    height: 100px;
    bottom: 25px;
    right: 13vw;

    @media (min-width: 1181px) and (hover: hover) and (pointer: fine) {
      bottom: 45px;
      right: 65px;
    }
  }

  &Inner {
    width: 220px;
    height: 80px;
    left: 24px;
    bottom: 10px;

    .icon {
      &, img {
        width: 32px;
        height: 32px;
      }
      margin: 0 5px;
    }

    .phone {
      &Link {
        font-size: 20px;
      }
    }

    //@media (min-width: 1181px) and (hover: hover) and (pointer: fine) {
    //  left: 30px;
    //  bottom: 10px;
    //}
  }
}

.icons {
  display: flex;
}

.icon {
  &, img {
    width: 40px;
    height: 40px;
  }
  border-radius: 10px;
  overflow: hidden;
  margin: 0 7px;
  background: #fff;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
  transition: .2s all;

  &.active,
  &:hover {
    filter: brightness(0.8);
  }
}

.phone {
  margin-top: 10px;

  &Link {
    font-size: 26px;
    text-shadow: 1px 1px 2px rgb(0 0 0 / 20%);
    transition: .2s all;

    &.active,
    &:hover {
      filter: brightness(0.82);
    }
  }
}
