@import 'src/styles/vendor/animate.scss';

.albumsListWrapper {
  position: fixed;
  z-index: 9;
  $time: 1s;
  -webkit-animation: fadeIn $time;
  -moz-animation: fadeIn $time;
  -ms-animation: fadeIn $time;
  -o-animation: fadeIn $time;
  animation: fadeIn $time;
}

.albumsListContainer {
  height: 100vh;
  position: relative;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 15px 3px 9px rgb(0 0 0 / 60%);
  user-select: none;
  margin: 0;
  z-index: 10;

  $time: 0.2s;
  -webkit-animation: fadeInLeft $time;
  animation: fadeInLeft $time;

  width: 35vw;

  @media (max-width: 420px) {
    width: 86vw;
  }

  a {
    margin-bottom: 2px;
    display: block;
    color: #fff;
    padding: 10px 10px 10px 25px;
    text-decoration: none;
    text-align: left;
    font-size: 11pt;
    text-transform: uppercase;
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .2s all;

    &.active,
    &:hover {
      color: #fff;
      background: rgba(0, 42, 8, 1);
      box-shadow: 1px 0 8px rgb(0 0 0 / 50%) inset;
    }
  }
}

.logo {
  transition: .1s all;
  user-select: none;
  display: block;
  width: 370px;
  height: 136px;
  z-index: 9;
  margin-bottom: 16px;

  &:hover {
    filter: opacity(0.95) sepia(20%);
  }

  img {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
    margin-top: 6px;
  }
}

.mobileMenuButton {
  position: fixed;
  top: 10px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #444;
  color: #fff;
  opacity: 1;
  border-radius: 50%;
  right: 24px;
  bottom: 24px;
  z-index: 9;
  border: none;
  cursor: pointer;
  user-select: none;
  transition: .2s all;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  img {
    width: 32px;
    height: 32px;
  }

  &Hidden {
    display: none;
  }

  &:hover {
    background: #000;
    opacity: 1;
  }
}
