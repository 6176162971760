@import 'src/styles/vendor/animate.scss';

.container {
  display: flex;
}

.imageContainer {
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.photos {
  user-select: none;

  &Hidden {
    opacity: 0;
  }

  a {
    margin: 0 2px;
  }

  img {
    cursor: pointer;
    object-fit: cover;
    -webkit-transition: 1s -webkit-filter linear;
    transition: .15s opacity linear;

    &:hover {
      opacity: 0.8;
      //filter: brightness(1.17) opacity(1);
    }
  }

  @media (hover: hover) and (pointer: fine) {
    margin-left: 280px;
  }
}
