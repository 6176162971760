@import 'src/styles/vendor/animate.scss';

.logo {
  transition: .15s all;
  user-select: none;
  display: block;
  position: absolute;
  top: 45px;
  left: 65px;
  z-index: 9;

  $time: 0.7s;
  -webkit-animation: fadeIn $time;
  animation: fadeIn $time;

  &, img {
    width: 270px;
    height: 100px;
  }

  &:hover {
    filter: opacity(0.95) sepia(20%);
  }

  img {
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  }

  @media (max-width: 844px) {
    width: auto;
    height: 100px;
    top: 30px;
    left: 30px;
  }

  @media (orientation: portrait) and (max-width: 844px) and (hover: none) {
    left: 13vw;

    img {
      height: 100px;
    }
  }
}

.slideContainer {
  user-select: none;
  width: 100vw;
  height: 100vh;
  margin: auto;
  overflow: hidden;
  cursor: pointer;

  > img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }
}

.eachSlide {
  width: 100vw;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    width: 100vw;
    height: 100vh;
  }

  span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
  }
}

.imageContainer {
  width: 100%;
  overflow: hidden;

  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    object-position: center;

    @media (orientation: portrait) and (max-width: 844px) and (hover: none) {
      width: auto;
    }
  }
}
