.scrollUp {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #444;
  color: #fff;
  opacity: 1;
  border-radius: 50%;
  right: 24px;
  bottom: 24px;
  z-index: 9;
  border: none;
  cursor: pointer;
  user-select: none;
  transition: .2s all;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

  img {
    width: 24px;
    height: 24px;
  }

  &Hidden {
    display: none;
  }

  &:hover {
    background: #000;
    opacity: 1;
  }
}
