@import 'src/styles/vendor/animate.scss';

body:not(.is-using-mouse) .fancybox__container :focus, .fancybox__container :focus {
  box-shadow: none;
}

img[data-fancybox] {
  cursor: pointer;
}

.fancybox__progress {
  display: none;
}

.fancybox__content {
  position: relative;
  z-index: 2;

  &:before {
    opacity: 0.65;
    display: block;
    content: "";
    bottom: 35px;
    right: calc(50% - 125px);
    width: 250px;
    height: 80px;
    position: absolute;
    background: url('../assets/logo.png') no-repeat center;
    background-size: contain;
    z-index: 9;

    @media (max-width: 1023px) {
      bottom: 25px;
      right: calc(50% - 90px);
      width: 180px;
      height: 40px;
    }
  }
}

@media (min-width: 1024px) {
  .fancybox__content img {
    cursor: pointer;
  }
  .fancybox__slide.is-draggable .fancybox__content img {
    cursor: grabbing;
  }
  .fancybox__slide {
    padding: 24px 100px 24px 100px;
  }
  .carousel__button {
    &.is-prev,
    &.is-next {
      display: flex;
      height: calc(100vh - 80px);
      width: 20%;
      border-radius: 0;
      outline: none;
      border: none;
      box-shadow: none;

      svg {
        transition: transform .2s;
        transform: scale(1);
      }

      &:hover svg {
        transform: scale(1.6);
      }
    }

    &.is-prev {
      cursor: w-resize;
      justify-content: flex-start;
    }

    &.is-next {
      cursor: e-resize;
      justify-content: flex-end;
    }
  }
}
