@import 'vendor/reset.scss';
@import 'fancybox.scss';

body {
  font-family: 'Trebuchet MS', 'Trebuchet', 'Calibri', 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background: #000;
  color: #fff;
  font-size: 12pt;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: #fff;
}
